import React from 'react'
import '../styles/imagewatermark.css'
import watermarkImage from '../images/CIHLogo.jpg'

const ImageWatermark = () => {
  return (
    <div className='imagewatermark'>
      <img src={watermarkImage} alt='Watermark' />
    </div>
  )
}

export default ImageWatermark
