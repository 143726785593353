import React from 'react'

import { useMsal } from '@azure/msal-react'
import { StaticImage } from 'gatsby-plugin-image'
import ImageWatermark from './ImageWaterMark'

import { Box, Button, Container, Typography } from '@mui/material'

import { loginRequest } from '../utils/auth'
import Footer from './Footer'

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */
export const LoginPage = (): any => {
  const { instance } = useMsal()

  const handleLogin = (loginType: string): any => {
    if (loginType === 'redirect') {
      instance.loginRedirect({
        ...loginRequest,
        redirectUri: '/'
      }).catch((e) => {
        console.log(e)
      })
    }
  }
  return (
    <Container component='main'>
      <ImageWatermark />
      <Box
        sx={{
          marginTop: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography color='rgb(48, 48, 197)' mb={10} variant='h3'>Cloud Innovation Hub, at the heart of...</Typography>

        <StaticImage
          src='../images/Unilever-logo-large.svg'
          alt=''
          width={450}
        />

        <Button backgroundColor='rgb(48, 48, 197)' variant='contained' onClick={() => handleLogin('redirect')}>
          Sign in using Redirect
        </Button>
      </Box>

      <Footer />
    </Container>
  )
}
