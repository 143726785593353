import * as React from 'react'
import { Box, Typography } from '@mui/material'

import '../styles/footer.css'

const Footer = (): JSX.Element => {
  return (
    <Box component='footer' className='footer' marginTop={20}>
      <Typography variant='h6' align='center'>
        &copy; {new Date().getFullYear()} <span>Cloud Innovation Hub</span>
        <br />
        <Typography variant='h6'>
          Powered by CPS
        </Typography>
      </Typography>
    </Box>
  )
}

export default Footer
