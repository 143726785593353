// Debug and development
export const nodeEnv = process.env.NODE_ENV
export const isDevelopment = Boolean(nodeEnv === 'development' && process.env.SITE_URL?.includes('localhost'))
export const skipAuthentication = process.env.SKIP_AUTHENTICATION === 'true'
export const isRpaOptionRequired = process.env.RPA_REQUIRED ?? false
export const isProduction = process.env.IS_PRODUCTION ?? false
// Services URLs
export const dbOperatorUrl = process.env.DB_OPERATOR_FA_URL ?? ''
export const createVmsUrl = process.env.CREATE_VMS_FA_URL ?? ''
export const apiMUrl = process.env.APIM_URL ?? ''
export const getCloudDetailsFunctionUrl = process.env.GET_CLOUD_DETAILS_FA_URL ?? ''
export const decommVmsUrl = process.env.DECOM_RESOURCE_FA_URL ?? ''
// Auth
export const authClientId = process.env.AUTH_CLIENT_ID ?? ''
export const authAuthority = process.env.AUTH_AUTHORITY ?? ''
export const authRedirectUri = process.env.AUTH_REDIRECT_URI ?? ''


